import {BlogArticlesPayload} from 'api/blogArticle/loadBlogArticles';
import {BlogLayout} from 'components/BlogLayout';
import {BlogMainProps, BlogMain as BlogMainBase} from 'components/BlogMain';
import {Metadata} from 'components/Metadata';
import {loadBlogArticlesFx, $blogArticles} from 'models/blogArticles';
import {toKey} from 'models/blogArticles/utils';
import {NextPageContext} from 'next';
import React from 'react';
import {defineMessages} from 'react-intl';

const messages = defineMessages({
  description: {
    defaultMessage: 'Subscribe to get latest news and exclusive content',
    description: 'Description for JoomPro blog websitre used in meta tags',
  },
  title: {
    defaultMessage: 'JoomPro Blog',
    description: 'Title for JoomPro blog websitre used in meta tags',
  },
});

export default function BlogMain({allArticles, joomProArticles}: BlogMainProps): React.ReactElement {
  return (
    <>
      <Metadata description={messages.description} title={messages.title} />
      <BlogLayout>
        <BlogMainBase allArticles={allArticles} joomProArticles={joomProArticles} />
      </BlogLayout>
    </>
  );
}

BlogMain.getInitialProps = async ({store, locale}: NextPageContext): Promise<BlogMainProps> => {
  const allPayload: BlogArticlesPayload = {locale};
  const joomProPayload: BlogArticlesPayload = {
    locale,
    tagsOneOf: ['Sobre-nós', 'About us'],
  };

  await Promise.all([
    store.dispatch(loadBlogArticlesFx, allPayload),
    store.dispatch(loadBlogArticlesFx, joomProPayload),
  ]);

  const blogArticlesState = store.getState($blogArticles);
  const allArticles = blogArticlesState.byKey[toKey(allPayload)]?.data || [];

  const joomProArticles = blogArticlesState.byKey[toKey(joomProPayload)]?.data || [];
  return {
    allArticles,
    joomProArticles,
  };
};
