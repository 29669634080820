import {BlogArticlesLayout} from 'components/BlogArticlesLayout';
import {BlogSectionCallToAction} from 'components/BlogSectionCallToAction';
import {BlogSectionProjects} from 'components/BlogSectionProjects';
import {BlogSectionSubscribe} from 'components/BlogSectionSubscribe';
import {BlogArticleLite} from 'lib/blogArticle/types';
import {buildMessage} from 'lib/intl';
import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import {reverseUrl} from 'lib/router';
import React from 'react';
import {useIntl, defineMessages} from 'react-intl';
import styles from './index.module.scss';

export type BlogMainProps = {
  allArticles: BlogArticleLite[];
  joomProArticles: BlogArticleLite[];
};

const messages = defineMessages({
  aboutJoomPro: {
    defaultMessage: 'About JoomPro',
    description: 'Title for group of articles on main blog page',
  },
  newestPosts: {
    defaultMessage: 'Newest posts',
    description: 'Title for group of articles on main blog page',
  },
});

export function BlogMain({allArticles, joomProArticles}: BlogMainProps): React.ReactElement {
  const language = useLanguage();
  const intl = useIntl();
  return (
    <div className={styles.root}>
      <BlogArticlesLayout header={<BlogArticlesLayout.Header title={buildMessage(intl, messages.newestPosts)} />}>
        {allArticles.map((data) => (
          <BlogArticlesLayout.Card
            date={data.date}
            description={data.description}
            imageMain={data.imageMain}
            key={data.slug}
            readTimeMin={data.readTimeMin}
            slug={data.slug}
            title={data.title}
          />
        ))}
      </BlogArticlesLayout>
      {joomProArticles.length > 0 && (
        <BlogArticlesLayout
          header={
            <BlogArticlesLayout.Header
              href={reverseUrl.blogPostsByTag({
                tag: decodeURIComponent(language === Language.PT_BR ? 'Sobre-nós' : 'About us'),
              })}
              title={buildMessage(intl, messages.aboutJoomPro)}
            />
          }
        >
          {joomProArticles.map((data) => (
            <BlogArticlesLayout.Card
              date={data.date}
              description={data.description}
              imageMain={data.imageMain}
              key={data.slug}
              readTimeMin={data.readTimeMin}
              slug={data.slug}
              title={data.title}
            />
          ))}
        </BlogArticlesLayout>
      )}
      <BlogSectionCallToAction />
      <BlogSectionSubscribe theme='dark' />
      <BlogSectionProjects />
    </div>
  );
}
