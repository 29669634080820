import {Image} from 'components/Image';
import {Reducer} from 'components/Layout/Reducer';
import {Link} from 'components/Link';
import {buildMessage} from 'lib/intl';
import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import {reverseUrl} from 'lib/router';
import {JOOM_PRO_ANALYTICS_URL} from 'lib/router/constants';
import React from 'react';
import {useIntl, defineMessages} from 'react-intl';
import {Button} from 'uikit/Button';
import styles from './index.module.scss';

const messagesJoomPro = defineMessages({
  button: {
    defaultMessage: 'Learn more',
    description: 'Button for JoomPro section on main blog page',
  },
  description: {
    defaultMessage:
      // eslint-disable-next-line max-len
      'We provide businesses a turnkey solution for purchasing and shipping from China. Our technology driven approach helps quickly find the best suppliers and delivery services so that clients receive their goods reliably and at a great deal.',
    description: 'Description for JoomPro section on main blog page',
  },
  title: {
    defaultMessage: 'JoomPro',
    description: 'Title for JoomPro section on main blog page',
  },
});

const messagesAnalytics = {
  button: 'Acesse grátis',
  description:
    // eslint-disable-next-line max-len
    'Plataforma de análises para vendedores do Mercado Livre Brasil. Você pode planejar suas compras de mercadorias de forma estratégica e eficaz. Amplie seus negócios e aumente seu lucro com os produtos certos!',
  title: 'JoomPro Analytics',
};

export function BlogSectionProjects(): React.ReactElement {
  const intl = useIntl();
  const language = useLanguage();
  return (
    <div className={styles.container}>
      <Reducer>
        <div className={styles.root}>
          <div className={styles.grid}>
            <div className={styles.imageContainer}>
              <Image className={styles.image} loading='lazy' src='/illustrations/blog/section_projects_joompro.jpg' />
            </div>
            <div className={styles.content}>
              <div className={styles.text}>{buildMessage(intl, messagesJoomPro.title)}</div>
              <div className={styles.description}>{buildMessage(intl, messagesJoomPro.description)}</div>
              <Link href={reverseUrl.main()}>
                <Button color='black' shape='round' size='large' type='button'>
                  {buildMessage(intl, messagesJoomPro.button)}
                </Button>
              </Link>
            </div>
          </div>
          {language === Language.PT_BR && (
            <div className={styles.grid}>
              <div className={styles.content}>
                <div className={styles.text}>{buildMessage(intl, messagesAnalytics.title)}</div>
                <div className={styles.description}>{buildMessage(intl, messagesAnalytics.description)}</div>
                <Link href={JOOM_PRO_ANALYTICS_URL.root}>
                  <Button color='black' shape='round' size='large' type='button'>
                    {buildMessage(intl, messagesAnalytics.button)}
                  </Button>
                </Link>
              </div>
              <div className={styles.imageContainer}>
                <Image
                  className={styles.image}
                  loading='lazy'
                  src='/illustrations/blog/section_projects_analytics.jpg'
                />
              </div>
            </div>
          )}
        </div>
      </Reducer>
    </div>
  );
}
