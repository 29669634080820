import {Image} from 'components/Image';
import {Reducer} from 'components/Layout/Reducer';
import {Link} from 'components/Link';
import {buildMessage} from 'lib/intl';
import {JOOM_PRO_ANALYTICS_URL} from 'lib/router/constants';
import React from 'react';
import {useIntl, defineMessages} from 'react-intl';
import {Button} from 'uikit/Button';
import styles from './index.module.scss';

const messages = defineMessages({
  button: {
    defaultMessage: 'Free access',
    description: 'Button for section on main blog page',
  },
  description: {
    defaultMessage: 'The most up-to-date data and exclusive insights about sales on Mercado Livre',
    description: 'Description for section on main blog page',
  },
  title: {
    defaultMessage: 'JoomPro Analytics',
    description: 'Title for section on main blog page',
  },
});

export function BlogSectionCallToAction(): React.ReactElement {
  const intl = useIntl();
  return (
    <div className={styles.container}>
      <Reducer>
        <div className={styles.grid}>
          <div className={styles.imageContainer}>
            <Image className={styles.image} loading='lazy' src='/illustrations/blog/call_to_action.jpg' />
          </div>
          <div className={styles.content}>
            <div className={styles.text}>{buildMessage(intl, messages.title)}</div>
            <div className={styles.description}>{buildMessage(intl, messages.description)}</div>
            <div className={styles.button}>
              <Link href={JOOM_PRO_ANALYTICS_URL.root}>
                <Button color='outline-white' shape='round' size='large' type='button'>
                  {buildMessage(intl, messages.button)}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Reducer>
    </div>
  );
}
